// SearchProducts.js
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { searchProducts } from '../../api';
import Footer from '../Footer';
import PlaceholderText from './PlaceholderText';
import Pagination from './ProductList/Pagination';
import ProductList from './ProductList/ProductList';
import ScoreOverview from './ProductList/ScoreOverview';
import ScrollIcon from './ScrollIcon';
import SearchBar from './SearchBar';


const SearchProducts = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [nbOfPages, setNbOfPages] = useState(0);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');
  const searchInputRef = useRef(null);
  const cancelTokenRef = useRef(null);


  const scoreDescriptions = [
    {
      logo: '/images/ecoscore_a.png',
      title: 'Green Score',
      description: (
        <div>
          Le <strong style={{ fontSize: '1.1em', color: '#666' }}>Green Score</strong> mesure l'impact environnemental global du produit. Un score élevé indique un impact <strong style={{ fontSize: '1.1em', color: '#666' }}>réduit</strong> sur l'environnement.
          <br />
          Pour plus d&apos;infos, consultez la&nbsp;
          <a
            href="https://docs.score-environnemental.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#578c47', textDecoration: 'underline' }}
          >
            documentation Score Environnemental
          </a>.
          <br />
          <img
            src="/images/greenscore-details.svg"
            alt="Détails Green Score"
            style={{ maxWidth: '100%', marginTop: '8px' }}
          />
        </div>
      ),
    },
    {
      logo: '/images/nutriscore_a.png',
      title: 'Nutri-Score',
      description: (
        <div>
          Le <strong style={{ fontSize: '1.1em', color: '#666' }}>Nutri-Score</strong> évalue la qualité nutritionnelle du produit, allant de A (optimal) à E (moins favorable).
          <br />
          Pour plus d&apos;infos, consultez&nbsp;
          <a
            href="https://www.santepubliquefrance.fr/determinants-de-sante/nutrition-et-activite-physique/articles/nutri-score"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#578c47', textDecoration: 'underline' }}
          >
            les informations de Santé Publique France
          </a>.
          <br />
          <img
            src="/images/nutriscore-details.png"
            alt="Détails Nutri-Score"
            style={{ maxWidth: '100%', marginTop: '8px' }}
          />
        </div>
      ),
    },
    {
      logo: '/images/nova_1.png',
      title: 'Nova Score',
      description: (
        <div>
          Le <strong style={{ fontSize: '1.1em', color: '#666' }}>Nova Score</strong> indique le degré de transformation du produit. Un score bas signifie un produit <strong style={{ fontSize: '1.1em', color: '#666' }}>peu transformé</strong> et souvent plus sain.
          <br />
          Pour plus d&apos;infos, consultez&nbsp;
          <a
            href="https://fr.wikipedia.org/wiki/NOVA_(nutrition)"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#578c47', textDecoration: 'underline' }}
          >
            la page Wikipedia
          </a>.
          <br />
          <img
            src="/images/novascore-details.png"
            alt="Détails Nova Score"
            style={{ maxWidth: '100%', marginTop: '8px' }}
          />
        </div>
      ),
    },
  ];
  
  const fetchProducts = async (pageNumber = 1) => {
    if (!query.trim()) return;

    setLoading(true);

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
    }
    cancelTokenRef.current = axios.CancelToken.source();

    try {
      console.log('Fetching products...');
      const data = await searchProducts(query, pageNumber, cancelTokenRef.current.token);
      setResults(data.products || []);
      setNbOfPages(Math.ceil(data.count / 50));
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Requête annulée');
      } else {
        console.error('Erreur lors de la recherche :', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setSearchTerm(query);
    fetchProducts();
  };

  const handleCancelSearch = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
    }
    setQuery('');
    setSearchTerm('');
    setResults([]);
    setLoading(false);

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    const handleScroll = () => setShowScrollIcon(window.scrollY > 200);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <Box>
      <Box sx={{ minHeight: '90vh', position: 'relative', bgcolor: '#f3f8ec', pt: { xs: 15, md: 15 } }}>
        <Typography
          variant="h4"
          align="center"
          sx={{ mb: 3, fontWeight: 'bold', color: '#578c47', mx: 2 }}
        >
          Recherchez un produit
        </Typography>

        <SearchBar
          query={query}
          setQuery={setQuery}
          handleSearch={handleSearch}
          handleCancelSearch={handleCancelSearch}
          handleKeyPress={handleKeyPress}
          inputRef={searchInputRef}
          placeholderText={placeholderText}
          loading={loading}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            fontSize: '0.9rem',
            color: 'gray',
          }}
        >
          <Typography variant="body2" sx={{ mr: 1, fontSize: '0.6rem' }}>
            *Origine des données :
          </Typography>
          <a
            href="https://world.openfoodfacts.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/off-logo-horizontal-light.svg"
              alt="OpenFoodFacts"
              style={{ height: '15px', verticalAlign: 'middle' }}
            />
          </a>
        </Box>

        <PlaceholderText setPlaceholderText={setPlaceholderText} />

        <ScoreOverview scoreDescriptions={scoreDescriptions} />

        <ProductList products={results} loading={loading} />

        {/* Pagination et bouton de scroll */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            left: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            zIndex: 1100,
          }}
        >
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Pagination page={page} setPage={setPage} nbOfPages={nbOfPages} fetchProducts={fetchProducts} />
          </Box>
          <Box>
            <ScrollIcon show={showScrollIcon} searchInputRef={searchInputRef} />
          </Box>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default SearchProducts;
