// NutritionalValuesSection.js
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, List, ListItem, Typography } from '@mui/material';
import React, { useState } from 'react';

const NutritionalValuesSection = ({ nutriments }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prev) => !prev);

  return (
    <Box
      sx={{
        border: '2px solid #A8D5BA',
        borderRadius: '16px',
        bgcolor: 'rgba(200,230,201,0.15)',
        transition: 'all 0.3s ease',
        mb: 2,
      }}
    >
      {/* En-tête cliquable */}
      <Box
        onClick={toggleMenu}
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            borderColor: '#8CBF88',
            bgcolor: 'rgba(200,230,201,0.3)',
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#578c47' }}>
          Valeurs nutritionnelles
        </Typography>
        <IconButton size="small" sx={{ p: 0 }}>
          {isOpen ? <ExpandLessIcon sx={{ color: '#578c47' }} /> : <ExpandMoreIcon sx={{ color: '#578c47' }} />}
        </IconButton>
      </Box>
      {/* Contenu déroulé */}
      <Collapse in={isOpen} timeout={300} unmountOnExit>
        <Box sx={{ p: 2 }}>
          {nutriments && Object.keys(nutriments).length > 0 ? (
            <List sx={{ p: 0 }}>
              {Object.entries(nutriments).map(([key, value]) => (
                <ListItem key={key} sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant="body2" sx={{ color: '#666' }}>
                    {key.replace(/_/g, ' ')} : {value}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" sx={{ color: '#757575' }}>
              Aucune information nutritionnelle disponible.
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default NutritionalValuesSection;
