import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Typography, keyframes } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

// Animation de fade-in pour le bouton et le texte explicatif
const fadeInSmooth = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const WhyEngageSection = () => {
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  
  const { ref: buttonRef, inView: buttonInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
        <Box
          sx={{
            // backgroundColor: 'rgba(183, 224, 152, 0.2)',
            width: '100vw',
            marginLeft: 'calc(-50vw + 50%)', // Assure que la section occupe toute la largeur de l'écran
            position: 'relative',
            py: { xs: 6, md: 10 },
    
          }}
        >
          {/* Arrière-plan image ambiance */}
          <Box
            sx={{
              display: { xs: 'none', md: 'block' }, // Visible uniquement sur ordinateur
              position: 'absolute',
              bottom: '-230px',
              right: '-100px',
              height: '800px',
              width: '800px',
              zIndex: -2, 
              backgroundImage: 'url(/images/ambiance-background.png)', 
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              opacity: 0.2, 
              pointerEvents: 'none',
            }}
          />
          <Box
            sx={{
              display: { xs: 'none', md: 'block' }, // Visible uniquement sur ordinateur
              position: 'absolute',
              bottom: '-400px',
              left: '-200px',
              height: '800px',
              width: '800px',
              zIndex: -2, 
              backgroundImage: 'url(/images/ambiance-background2.png)', 
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              opacity: 0.2, 
              pointerEvents: 'none',
            }}
          />
    <Box
      sx={{
        position: 'relative',
        overflow: 'visible',
        py: 8,
        px: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: 2,
        backgroundColor: '#f3f8ec',

      }}
    >
      {/* Titre sans animation */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 6, color: '#43a047' }}>
        <strong style={{ color: '#616161' }}>Pourquoi</strong> on s'engage ? 🤔
      </Typography>

      {/* Texte explicatif avec animation d'entrée fluide */}
      <Typography
        ref={textRef}
        variant="body1"
        sx={{
          fontSize: '1.2rem',
          color: '#424242',
          maxWidth: '600px',
          mb: 4,
          '& strong': { color: '#388E3C' },
          opacity: textInView ? 1 : 0,
          animation: textInView ? `${fadeInSmooth} 0.8s ease-out` : 'none',
        }}
      >
        Nous voulons nous <strong>engager intelligemment</strong>, découvre comment et pourquoi on se démène.
      </Typography>

      {/* Bouton avec animation d'entrée et survol fluide */}
      <Button
        ref={buttonRef}
        variant="contained"
        component={Link}
        to="/espace-membres"
        endIcon={<ArrowForwardIcon sx={{ animation: 'bounceRight 1.5s ease-in-out infinite' }} />}
        sx={{
          bgcolor: '#D35400',
          color: 'white',
          fontWeight: 'bold',
          overflow: 'hidden',
          px: 4,
          py: 1,
          position: 'relative',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          opacity: 0, // Définit l'opacité de base à 0 pour éviter le flicker initial
          animation: buttonInView ? `${fadeInSmooth} 1s ease-out forwards` : 'none', // 'forwards' maintient l'état final

          // Hover plus fluide
          transition: 'transform 0.2s ease-out, box-shadow 0.2s ease-out, background-color 0.2s ease-out',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#E67E22',
          },
          
          // Animation de reflet sur le bouton
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '-100%',
            width: '25%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.3)',
            transform: 'skewX(-20deg)',
            animation: 'moveReflet 2.5s cubic-bezier(0.25, 0.8, 0.5, 1) infinite',
          },
          '@keyframes moveReflet': {
            '0%': { left: '-100%' },
            '100%': { left: '150%' },
          },

          // Nouvelle animation pour l'icône de la flèche
          '@keyframes bounceRight': {
            '0%': { transform: 'translateX(0)' },
            '50%': { transform: 'translateX(4px)' },
            '100%': { transform: 'translateX(0)' },
          },
        }}
      >
        En savoir plus
      </Button>
    </Box>
  </Box>
  );
};

export default WhyEngageSection;
