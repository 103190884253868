import { Box, Button, IconButton, Typography } from '@mui/material';
import React from 'react';

const Pagination = ({ page, setPage, nbOfPages, fetchProducts }) => {
  if (nbOfPages <= 1) return null;

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prev) => {
        const prevPage = prev - 1;
        fetchProducts(prevPage);
        return prevPage;
      });
    }
  };

  const handleNextPage = () => {
    if (page < nbOfPages) {
      setPage((prev) => {
        const nextPage = prev + 1;
        fetchProducts(nextPage);
        return nextPage;
      });
    }
  };

  return (
    <Box
      sx={{
        height: '50px',
        maxWidth: '90%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        borderRadius: '25px',
        padding: '0px 15px',
        zIndex: 1000,
        gap: '10px',
        border: '1px solid #5cb85c',
      }}
    >
      <IconButton
        disabled={page === 1}
        onClick={handlePreviousPage}
        sx={{
          color: page >= nbOfPages ? 'gray' : 'green',
          transition: '0.2s',
          '&:hover': {
            backgroundColor: 'rgba(0, 128, 0, 0)',
            color: page >= nbOfPages ? 'gray' : 'rgba(0, 128, 0, 0.1)',
          },
          fontWeight: 'bold',
        }}
      >
        &lt;
      </IconButton>

      {(() => {
        const elements = [];
        const displayRange = 2;
        elements.push(
          <Button
            key={1}
            onClick={() => {
              setPage(1);
              fetchProducts(1);
            }}
            sx={{
              minWidth: '30px',
              backgroundColor: page === 1 ? 'green' : 'transparent',
              color: page === 1 ? 'white' : 'gray',
              '&:hover': {
                backgroundColor: 'rgba(0, 128, 0, 0.1)',
              },
              fontWeight: 'bold',
            }}
          >
            1
          </Button>
        );
        if (page > displayRange + 2) {
          elements.push(
            <Typography
              key="start-ellipsis"
              sx={{ mx: 1, fontSize: '1rem', color: 'gray', fontWeight: 'bold' }}
            >
              ...
            </Typography>
          );
        }
        const startPage = Math.max(2, page - displayRange);
        const endPage = Math.min(nbOfPages - 1, page + displayRange);
        for (let i = startPage; i <= endPage; i++) {
          elements.push(
            <Button
              key={i}
              onClick={() => {
                setPage(i);
                fetchProducts(i);
              }}
              sx={{
                minWidth: '30px',
                backgroundColor: page === i ? 'green' : 'transparent',
                color: page === i ? 'white' : 'gray',
                '&:hover': {
                  backgroundColor: 'rgba(0, 128, 0, 0.1)',
                },
                fontWeight: 'bold',
              }}
            >
              {i}
            </Button>
          );
        }
        if (page < nbOfPages - displayRange - 1) {
          elements.push(
            <Typography
              key="end-ellipsis"
              sx={{ mx: 1, fontSize: '1rem', color: 'gray', fontWeight: 'bold' }}
            >
              ...
            </Typography>
          );
        }
        if (nbOfPages > 1) {
          elements.push(
            <Button
              key={nbOfPages}
              onClick={() => {
                setPage(nbOfPages);
                fetchProducts(nbOfPages);
              }}
              sx={{
                minWidth: '30px',
                backgroundColor: page === nbOfPages ? 'green' : 'transparent',
                color: page === nbOfPages ? 'white' : 'gray',
                '&:hover': {
                  backgroundColor: 'rgba(0, 128, 0, 0.1)',
                },
                fontWeight: 'bold',
              }}
            >
              {nbOfPages}
            </Button>
          );
        }
        return elements;
      })()}

      <IconButton
        disabled={page >= nbOfPages}
        onClick={handleNextPage}
        sx={{
          color: page >= nbOfPages ? 'gray' : 'green',
          transition: '0.2s',
          '&:hover': {
            backgroundColor: 'rgba(0, 128, 0, 0)',
            color: page >= nbOfPages ? 'gray' : 'rgba(0, 128, 0, 0.1)',
          },
          fontWeight: 'bold',
        }}
      >
        &gt;
      </IconButton>
    </Box>
  );
};

export default Pagination;
