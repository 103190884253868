import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, Card, CardContent, CardMedia, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ProductCard = ({ product, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageValid, setImageValid] = useState(null);

  useEffect(() => {
    if (!product.image_url) {
      setImageValid(false);
      setImageLoaded(true);
      return;
    }

    let isMounted = true;
    const img = new Image();
    img.src = product.image_url;

    img.onload = () => {
      if (isMounted) {
        setImageValid(true);
        setImageLoaded(true);
      }
    };

    img.onerror = () => {
      if (isMounted) {
        setImageValid(false);
        setImageLoaded(true);
      }
    };

    return () => {
      isMounted = false;
    };
  }, [product.image_url]);

  // Formatage de la valeur CO₂ multipliée par le poids du produit
  const formatCO2Value = (value) => {
    // quantity sous la forme "100 g", "100 kg", "100 ml", "100 cl", "100 l" ou "100 mg"
    const quantity = product.quantity || '';
    // Extraction du nombre (en remplaçant la virgule par un point si nécessaire)
    const numStr = (quantity.match(/[\d.,]+/) || ['1'])[0].replace(',', '.');
    const num = parseFloat(numStr);
    
    let weightInKg = num;
    const qLower = quantity.toLowerCase();
    
    if (qLower.includes('kg')) {
      weightInKg = num;
    } else if (qLower.includes('g')) {
      weightInKg = num / 1000;
    } else if (qLower.includes('mg')) {
      weightInKg = num / 1e6;
    } else if (qLower.includes('l')) {
      weightInKg = num;
    } else if (qLower.includes('ml')) {
      weightInKg = num / 1000;
    } else if (qLower.includes('cl')) {
      weightInKg = num / 100;
    }
    
    return value ? `${(value * weightInKg).toFixed(2)} kg CO₂` : '? kg CO₂';
  };

  // Utilise uniquement l'écoscore (Green Score) pour déterminer la couleur du contour
  const getBorderColor = () => {
    const greenscore = product.ecoscore_grade?.toLowerCase() || 'e';
    const borderColorMap = {
      'a-plus': '#4CAF50',
      a: '#bfd9a3',
      b: '#D8F1BC',
      c: '#FFF4C2',
      d: '#FFD4B2',
      e: '#ff7d7d',
    };
    return borderColorMap[greenscore] || borderColorMap.e;
  };

  // Modification des couleurs pour la valeur CO₂ :
  // Vert pour <2, jaune pour <5, et un rouge un peu plus prononcé pour 5 ou plus.
  const getCO2TextColor = (value) => {
    if (value === null || value === undefined || isNaN(value) || value === 0) return '#757575';
    if (value < 2) return '#4CAF50';
    if (value < 5) return '#FFC107';
    return '#D32F2F';
  };

  // Calcul de la valeur CO₂ pondérée par le poids (poids par défaut = 1)
  const weight = product.weight || 1;
  const co2Base = product?.agribalyse?.co2_total || 0;
  const co2Value = co2Base * weight;

  // Condition pour afficher "*produit incomplet"
  const isIncomplete = 
    (!product.ecoscore_grade || product.ecoscore_grade.toLowerCase() === 'unknown') ||
    (!product.nutriscore_grade || product.nutriscore_grade.toLowerCase() === 'unknown') ||
    (!product.nova_group || product.nova_group === 'unknown');

  return (
    <Card
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.3s, border-color 0.3s',
        border: `4px solid ${getBorderColor()}`,
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
        backgroundColor: 'white',
        paddingX: 0,
        paddingY: 1,
        borderRadius: '16px',
        position: 'relative',
      }}
    >
      {/* Message "produit incomplet" en haut à gauche si un score est unknown */}
      {isIncomplete && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: 4,
            left: 4,
            color: 'red',
            zIndex: 10,
            width: '100%',
            bgcolor: 'white',
            pl: 2,
          }}
        >
          *informations manquantes
        </Typography>
      )}

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: 150,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#fafafa00',
          overflow: 'hidden',
        }}
      >
        {imageValid === null && <CircularProgress sx={{ color: '#4CAF5055' }} />}
        {imageValid && (
          <CardMedia
            component="img"
            src={product.image_url}
            alt={product.product_name}
            sx={{
              objectFit: 'contain',
              height: '100%',
              maxWidth: '100%',
            }}
          />
        )}
        {imageValid === false && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'gray',
            }}
          >
            <ImageNotSupportedIcon sx={{ fontSize: 50, color: 'gray' }} />
            <Typography variant="caption">Image non disponible</Typography>
          </Box>
        )}
      </Box>

      <CardContent
        sx={{
          textAlign: 'center',
          bgcolor: 'white',
          fontFamily: "'Montserrat', sans-serif",
        }}
      >
        <Typography variant="h6" component="div" noWrap>
          {product.product_name || 'Nom inconnu'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.brands || 'Marque inconnue'}
        </Typography>
        {/* Affichage de la donnée CO₂ pondérée */}
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            marginTop: 1,
            color: getCO2TextColor(co2Value),
          }}
        >
          {formatCO2Value(co2Value)}
        </Typography>
      </CardContent>

      {/* Ligne de séparation personnalisée */}
      <Box
        sx={{
          borderTop: `2px solid ${getBorderColor()}`,
          marginX: 2,
        }}
      />

      {/* Scores dans le nouvel ordre : Green Score, Nutri-Score, Nova Score */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '8px 16px',
        }}
      >
        <CardMedia
          component="img"
          src={`/images/ecoscore_${product.ecoscore_grade || 'unknown'}.png`}
          alt="Green Score"
          sx={{
            height: 40,
            width: 'auto',
            objectFit: 'contain',
          }}
        />

        <CardMedia
          component="img"
          src={`/images/nutriscore_${product.nutriscore_grade || 'unknown'}.png`}
          alt="Nutri-Score"
          sx={{
            height: 40,
            width: 'auto',
            objectFit: 'contain',
          }}
        />

        <CardMedia
          component="img"
          src={`/images/nova_${product.nova_group || 'unknown'}.png`}
          alt="Nova Score"
          sx={{
            height: 40,
            width: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Card>
  );
};

export default ProductCard;
