import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, CardMedia, Collapse, Container, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

// Données des scores
const scoreData = [
  {
    title: 'Empreinte CO2',
    image: '/images/co2_logo.png',
    description: 'L’empreinte carbone mesure la quantité totale de gaz à effet de serre émise tout au long du cycle de vie du produit.',
  },
  {
    title: 'Nutriscore',
    image: '/images/nutriscore_logo.png',
    description: 'Le Nutriscore évalue la qualité nutritionnelle des aliments sur une échelle de A à E, en fonction de leur teneur en nutriments.',
  },
  {
    title: 'Score Nova',
    image: '/images/novascore_logo.png',
    description: 'Le Score Nova classe les aliments en fonction de leur degré de transformation industrielle, de 1 (non transformé) à 4 (ultra-transformé).',
  },
  {
    title: 'Ecoscore',
    image: '/images/ecoscore_logo.png',
    description: 'L\'Ecoscore prend en compte l’impact environnemental d’un produit, notamment son mode de production et son emballage.',
  },
];

const ProductScoreSection = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Container
      id="product-score"
      maxWidth="lg"
      sx={{
        py: { xs: 6, md: 10 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        gap: 4,
      }}
    >
      {/* Image à gauche */}
      <Box
        ref={sectionRef}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: sectionInView ? 1 : 0,
          transition: sectionInView ? 'opacity 1s ease-in-out' : 'none',
        }}
      >
        <CardMedia
          component="img"
          image="/images/phone-product-score.png"
          alt="Produit avec scores CO2, Nutriscore, Nova, Ecoscore"
          sx={{
            maxWidth: { xs: '80%', md: '100%' },
            height: 'auto',
            filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
          }}
        />
      </Box>

      {/* Contenu texte avec cartes */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          opacity: sectionInView ? 1 : 0,
          transition: sectionInView ? 'opacity 1s ease-in-out' : 'none',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#43a047',
            textAlign: { xs: 'center', md: 'left' },
            mb: 3,
          }}
        >
          Scan 1.500.000 produits 📱
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#616161',
            fontSize: { xs: '1rem', md: '1.25rem' },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Explore en un clin d'œil <strong>l'impact de tes produits</strong> préférés sur l’environnement avec des scores simples :
        </Typography>

        <ScoreCards />
      </Box>
    </Container>
  );
};

const ScoreCards = () => {
  const [expanded, setExpanded] = useState(null);

  const handleExpandClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
      {scoreData.map((score, index) => {
        const { ref, inView } = useInView({
          triggerOnce: true,
          threshold: 0.2,
        });

        return (
          <Box
            ref={ref}
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#f9fdf6',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '8px 12px',
              fontSize: '0.875rem',
              opacity: inView ? 1 : 0,
              transform: inView ? 'translateY(0)' : 'translateY(20px)',
              transition: `opacity 0.6s ease-in-out ${index * 0.2}s, transform 0.6s ease-in-out ${index * 0.2}s, box-shadow 0.6s`,
              border: '2px solid #e3e3e3',
              '&:hover': {
                boxShadow: 'none',
                cursor: 'pointer',
              },
            }}
            onClick={() => handleExpandClick(index)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <img
                  src={score.image}
                  alt={score.title}
                  style={{ height: '35px', width: 'auto' }}
                />
                <Typography sx={{ fontWeight: 'bold', color: '#388E3C' }}>
                  {score.title}
                </Typography>
              </Box>
              <IconButton
                onClick={() => handleExpandClick(index)}
                sx={{ transform: expanded === index ? 'rotate(180deg)' : 'none' }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
            <Collapse in={expanded === index}>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '0.875rem',
                  color: '#555',
                  padding: '4px 8px',
                  backgroundColor: 'rgba(0, 128, 0, 0.05)',
                  borderRadius: '4px',
                }}
              >
                {score.description}
              </Typography>
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductScoreSection;
