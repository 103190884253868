// ProductModal.js
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getProductDetails } from '../../../api';
import ProductDetails from './ProductDetails';

const modalStyle = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'white',
  borderRadius: '16px',
  boxShadow: 24,
  p: 0,
  overflowY: 'auto',
  maxHeight: '80vh',
};

const ProductModal = ({ product, onClose }) => {
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!product?.code) return;
    setLoading(true);
    getProductDetails(product.code)
      .then((data) => setProductDetails(data.product))
      .catch(() => setProductDetails(null))
      .finally(() => setLoading(false));
    console.log(product);
  }, [product]);

  return (
    <Modal open={!!product} onClose={onClose} sx={{ zIndex: 99999 }}>
      <Box sx={modalStyle}>
        {/* En-tête fixé avec sticky */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '2px solid #578c47',
            backgroundColor: '#E8F5E9',
            mb: 2,
            py: 1,
            px: 2,
          }}
        >
          <Typography variant="h6" sx={{ color: '#578c47' , p:1}}>
            Détails du produit
          </Typography>
          <IconButton onClick={onClose} sx={{ color: 'gray' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {loading ? (
          <Typography variant="h6" align="center">
            Chargement des détails du produit...
          </Typography>
        ) : productDetails ? (
          <ProductDetails productDetails={productDetails} />
        ) : (
          <Typography variant="body2" color="error" sx={{ textAlign: 'center' }}>
            Impossible de récupérer les détails du produit.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ProductModal;
