import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import React from 'react';

const ScrollIcon = ({ show, searchInputRef }) => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    searchInputRef.current?.focus();
  };

  return show && (
    <IconButton
      onClick={handleScrollToTop}
      sx={{
        position: 'fixed',
        right: '15px',
        bottom: '20px',
        zIndex: 1000,
        bgcolor: 'white',
        color: 'green',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.2)',
        '&:hover': {
          bgcolor: 'rgba(0, 128, 0, 0.1)',
        },
        width: 50, // ✅ Agrandit l'icône
        height: 50,
      }}
    >
      <KeyboardArrowUpIcon sx={{ fontSize: 32 }} /> {/* ✅ Icône plus grande */}
    </IconButton>
  );
};

export default ScrollIcon;
