import React, { useEffect, useRef } from "react";

const CO2Comparator = ({ value }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Nettoyer le conteneur avant chaque nouvel ajout
    if (containerRef.current) {
      containerRef.current.innerHTML = "";
    }

    // Créer le script pour intégrer l'iframe
    const script = document.createElement("script");
    script.setAttribute("name", "impact-co2");
    script.src = "https://impactco2.fr/iframe.js";
    script.dataset.type = "comparateur/etiquette-animee";
    script.dataset.search = `?value=${value}&comparisons=voiturethermique,baguette,biere,smartphone,thon,hotel&language=fr&theme=default`;
    script.async = true;

    // Ajouter le script au conteneur
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
  }, [value]); // Exécuter lorsque `value` change

  return (
    <div
      ref={containerRef}
    />
  );
};

export default CO2Comparator;
