// ScoreBubble.js
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

const ScoreBubble = ({ logo, title, description }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      {/* Bulle secondaire */}
      <Box
        onClick={handleOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(200,230,201,0.3)',
          border: '1px solid #A8D5BA',
          borderRadius: '16px',
          padding: '4px 12px',
          cursor: 'pointer',
          minWidth: '150px',
          height: '60px',
          gap: 1,
          transition: 'background-color 0.2s, border-color 0.2s',
          zIndex: 1000000,

          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderColor: '#8CBF88',
          },
        }}
      >
        <Box
          component="img"
          src={logo}
          alt={title}
          sx={{ width: 24, height: 'auto' }}
        />
        <Typography variant="subtitle2" sx={{ color: '#578c47', fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>

      {/* Modal d'explication */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            border: '2px solid #A8D5BA',
            borderRadius: '16px',
            zIndex: 1000001,
          },
        }}
        sx={{ zIndex: 1000002 }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E8F5E9',
            m: 0,
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" sx={{ color: '#578c47' }}>
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#578c47' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ backgroundColor: 'white', p: 2 }}>
          <Typography variant="body2" component="div" sx={{ color: '#666' }}>
            {description}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScoreBubble;
