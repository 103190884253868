// TeamSection.js
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const TeamSection = () => {
  const controls = useAnimation();

  const teamMembers = [
    {
      refName: 'victor',
      image: '/images/team-optimized/Victor.JPG',
      alt: 'Victor - Président de l\'association',
      name: 'Victor',
      role: "Président de l'association",
    },
    {
      refName: 'calixte',
      image: '/images/team-optimized/Calixte.jpg',
      alt: 'Calixte - Pôle Développement/Informatique',
      name: 'Calixte',
      role: 'Pôle Développement / Informatique',
    },
    {
      refName: 'jeanloup',
      image: '/images/team-optimized/Jean-Loup.JPG',
      alt: 'Jean-Loup - Pôle Développement/Informatique',
      name: 'Jean-Loup',
      role: 'Pôle Développement / Informatique',
    },
    {
      refName: 'adelie',
      image: '/images/team-optimized/Adélie_Hutin-Decock.JPG',
      alt: 'Adélie - Pôle Communication',
      name: 'Adélie',
      role: 'Pôle Communication',
    },
    {
      refName: 'ricardo',
      image: '/images/team-optimized/Ricardo.JPG',
      alt: 'Ricardo - Pôle Juridique',
      name: 'Ricardo',
      role: 'Pôle Juridique',
    },
  ];

  // On gère les refs pour intersection observer
  const refs = {};
  const inViews = {};
  teamMembers.forEach((member) => {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
    refs[member.refName] = ref;
    inViews[member.refName] = inView;
  });

  // Ref pour la photo de groupe
  const [refGroupPhoto, inViewGroupPhoto] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    Object.keys(inViews).forEach((key) => {
      if (inViews[key]) {
        controls.start('visible');
      }
    });
    if (inViewGroupPhoto) {
      controls.start('visible');
    }
  }, [controls, inViews, inViewGroupPhoto]);

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <Box
      sx={{
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        position: 'relative',
        py: { xs: 1, md: 2 },
        overflow: 'hidden',
        backgroundColor: '#fff', // fond blanc
        borderRadius: '16px 16px 0 0', // coins arrondis en haut
      }}
    >



      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#578c47', mb: 2, textAlign: 'center' }}>
            Notre équipe
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#616161', mb: 1, textAlign: 'center' }}>
            Découvrez les visages derrière Indice Fossile
          </Typography>
          {/* Photo de groupe avec un recadrage (coupe ~30% du bas) */}
          <motion.div
            ref={refGroupPhoto}
            initial="hidden"
            animate={inViewGroupPhoto ? 'visible' : 'hidden'}
            variants={itemVariants}
            style={{ textAlign: 'center', marginBottom: '40px' }}
          >
          <Box
            sx={{
              position: 'relative', // pour le positionnement absolu des éléments internes
              width: '100%',
              maxWidth: 800,
              mx: 'auto',
              borderRadius: '16px',
              height: { xs: 'auto', md: 400 }, // sur mobile, la hauteur s'adapte au contenu
            }}
          >
            <CardMedia
              component="img"
              image="/images/team-optimized/groupe_bg.png"
              alt="Photo de groupe"
              sx={{
                width: '100%',
                height: { xs: 'auto', md: '100%' },
                objectFit: 'cover',
                objectPosition: '50% 10%', // centré horizontalement, aligné en haut
              }}
            />
            <Box
              sx={{
                display: { xs: 'none', md: 'block' }, // masqué sur mobile
                position: 'absolute',
                top: '0%', // position verticalement à 30% du conteneur
                right: '-100px', // décalé vers la droite
                transform: 'translateY(-50%)', // centré verticalement par rapport à sa position
                height: 100,
                width: 100,
                backgroundImage: 'url(/images/background_rond.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                opacity: 1,
                pointerEvents: 'none',
                zIndex: 1,
              }}
            />
          </Box>
          </motion.div>

          {/* Titre de section */}

          {/* Grid pour l’équipe */}
          <Grid container spacing={4} justifyContent="center">
            {teamMembers.map((member) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={member.refName}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <motion.div
                  ref={refs[member.refName]}
                  initial="hidden"
                  animate={inViews[member.refName] ? 'visible' : 'hidden'}
                  variants={itemVariants}
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}
                >
                  <CardMedia
                    component="img"
                    image={member.image}
                    alt={member.alt}
                    sx={{
                      height: 140,
                      width: 140,
                      borderRadius: '50%',
                      mb: 2,
                      objectFit: 'cover',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388E3C', mb: 0.5 }}>
                    {member.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#616161' }}>
                    {member.role}
                  </Typography>
                </motion.div>
              </Grid>
            ))}
          </Grid>
      </Container>
    </Box>
  );
};

export default TeamSection;
