// CO2DetailsBubble.js
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';


 

const CO2DetailsBubble = ({ formattedCO2, impactDescription, details, co2Total, ecoscore_grade }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  // Utilise uniquement l'écoscore (Green Score) pour déterminer la couleur du contour
 const getBorderColor = () => {
    const greenscore = ecoscore_grade?.toLowerCase() || 'e';
    const borderColorMap = {
      'a-plus': '#4CAF50',
      a: '#bfd9a3',
      b: '#D8F1BC',
      c: '#FFF4C2',
      d: '#FFD4B2',
      e: '#ff7d7d',
    };
    return borderColorMap[greenscore] || borderColorMap.e;
  };

  var borderColor = getBorderColor(co2Total);
  if (impactDescription === 'Inconnu')
  {
    borderColor = '#757575';
  }

  return (
    <Box
      sx={{
        border: `2px solid ${borderColor}`,
        borderRadius: '16px',
        backgroundColor: `${borderColor}11`,
        transition: 'all 0.3s ease',
        mb: 0,
        mt: 2,
      }}
    >
      {/* En-tête cliquable */}
      <Box
        onClick={toggleOpen}
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: `${borderColor}05`,
          },
        }}
      >
<Typography variant="body2" sx={{ color: '#333', fontWeight: 'bold' }}>
  Impact CO₂ : 
  <Box component="span" sx={{
    display: 'inline-block',
    fontWeight: 'bolder',
    color: '#666',
    fontSize: '2rem',  // Taille augmentée
    padding: '0.2rem 0.5rem',
    borderRadius: '5px', // Arrondi des bords
    backgroundColor: formattedCO2 && formattedCO2 !== '0' ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)', // Fond rouge si donnée manquante
  }}>
    {formattedCO2 && formattedCO2 !== '0.00 kg CO₂' ? formattedCO2 : 'Donnée manquante'}
  </Box> 
</Typography>

        <IconButton size="small" sx={{ p: 0 }} onClick={toggleOpen}>
          {open ? (
            <ExpandLessIcon sx={{ color: '#333' }} />
          ) : (
            <ExpandMoreIcon sx={{ color: '#333' }} />
          )}
        </IconButton>
      </Box>
      {/* Détails déroulants */}
      <Collapse in={open} timeout={300} unmountOnExit>
        <Box
          sx={{
            p: 2,
            borderTop: `2px solid ${borderColor}`,
            backgroundColor: `${borderColor}01`,
          }}
        >
          {details}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CO2DetailsBubble;
