import { Group, VolunteerActivism } from '@mui/icons-material'; // Icônes pour les cartes
import { Box, Button, Grid, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import Footer from '../Footer';

const MemberPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f9fdf6', // Appliquer à toute la page
        minHeight: '100vh',
        margin: 0,
        padding: 0,
        overflowX: 'hidden',
      }}
    >
      <Container
        sx={{
          maxWidth: '100vw', // Assurer qu'il occupe toute la largeur
          padding: 0,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            py: { xs: 8, md: 12 },
            px: { xs: 2, md: 6 },
            margin: 0,
          }}
        >
          {/* Titre principal */}
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', color: '#43a047', mb: 4, mt: 6 }}
          >
            Engagez-vous pour un <strong style={{ color: '#616161' }}>impact réel</strong> 🌍
          </Typography>

          {/* Sous-titre */}
          <Typography
            variant="h6"
            sx={{
              color: '#616161',
              mb: 8,
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Votre soutien nous permet de développer des outils qui encouragent des
            choix responsables, et de continuer à sensibiliser aux enjeux
            environnementaux. Découvrez comment vous pouvez nous aider à aller encore
            plus loin.
          </Typography>

          {/* Grille des cartes */}
          <Grid
  container
  spacing={4}
  sx={{
    maxWidth: '100%',
    mx: 'auto',
    justifyContent: 'center', // Centre horizontalement les items
    alignItems: 'center', // Aligne verticalement si nécessaire
  }}
>
  {/* Carte : Faire un don */}
  <Grid item xs={12} md={6}>
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '12px',
        p: { xs: 3, md: 4 },
        textAlign: 'center',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        },
        maxWidth: '400px', // Largeur fixe pour chaque carte
        margin: '0', // Centre individuellement chaque carte
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 3 }}>
        <VolunteerActivism
          sx={{
            fontSize: '2.5rem',
            color: '#43a047',
            mr: 2,
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#388E3C' }}>
          Faire un don
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ color: '#616161', mb: 3 }}>
        Contribuez librement pour soutenir nos actions et nous permettre
        de continuer à développer une application gratuite et indépendante.
        Votre don fait la différence.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#43a047',
          color: 'white',
          fontWeight: 'bold',
          px: 4,
          py: 1,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#388E3C',
          },
        }}
        onClick={() =>
          window.open(
            'https://www.payasso.fr/indice-fossile/dons',
            '_blank'
          )
        }
      >
        Faire un don
      </Button>
    </Box>
  </Grid>

  {/* Carte : Devenir adhérent */}
  <Grid item xs={12} md={6}>
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '12px',
        p: { xs: 3, md: 4 },
        textAlign: 'center',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        },
        maxWidth: '400px', // Largeur fixe pour chaque carte
        margin: '0', // Centre individuellement chaque carte
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 3 }}>
        <Group
          sx={{
            fontSize: '2.5rem',
            color: '#ff9800',
            mr: 2,
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#f57c00' }}>
          Devenir adhérent
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ color: '#616161', mb: 3 }}>
        Rejoignez notre communauté et devenez acteur du changement. En tant
        qu’adhérent, vous soutenez directement nos projets et nos
        initiatives.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#ff9800',
          color: 'white',
          fontWeight: 'bold',
          px: 4,
          py: 1,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#f57c00',
          },
        }}
        onClick={() =>
          window.open(
            'https://www.payasso.fr/indice-fossile/adhesions',
            '_blank'
          )
        }
      >
        Devenir adhérent
      </Button>
    </Box>
  </Grid>
</Grid>


          
          {/* Section en bas */}
          <Box sx={{ mt: 8 }}>
            <Typography
              variant="body1"
              sx={{
                color: '#616161',
                fontSize: '1rem',
                maxWidth: '700px',
                mx: 'auto',
              }}
            >
              Merci pour votre engagement et votre soutien. Ensemble, nous construisons
              un avenir plus respectueux de l’environnement et des générations futures.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default MemberPage;
