// ProductDetails.js
import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import ScoreOverview from '../ProductList/ScoreOverview';
import CO2Comparator from './CO2Comparator';
import CO2DetailsBubble from './CO2DetailsBubble';
import IngredientsSection from './IngredientsSection';
import NutritionalValuesSection from './NutritionalValuesSection';


const getTrueImpactco2 = (value, quantity) => {
    const numStr = (quantity.match(/[\d.,]+/) || ['1'])[0].replace(',', '.');
    const num = parseFloat(numStr);
  
    let weightInKg = num;
    const qLower = quantity.toLowerCase();
  
    if (qLower.includes('kg')) {
      weightInKg = num;
    } else if (qLower.includes('g')) {
      weightInKg = num / 1000;
    } else if (qLower.includes('mg')) {
      weightInKg = num / 1e6;
    } else if (qLower.includes('l')) {
      weightInKg = num;
    } else if (qLower.includes('ml')) {
      weightInKg = num / 1000;
    } else if (qLower.includes('cl')) {
      weightInKg = num / 100;
    }

    return (value * weightInKg).toFixed(2);
};

    
// Formatage du CO₂ en fonction de la quantité
const formatCO2Value = (value, product) => {
    const valueInKg = getTrueImpactco2(value, product.quantity);
    return valueInKg ? `${valueInKg} kg CO₂` : '? kg CO₂';
};



const getImpactDescription = (type, value) => {
  if (!value) return 'Inconnu';
  if (type === 'nova') {
    if (value === 1) return 'Très peu transformé';
    if (value === 2) return 'Peu transformé';
    if (value === 3) return 'Transformé';
    if (value === 4) return 'Très transformé';
  }
  if (type === 'ecoscore' || type === 'nutriscore') {
    if (value.toLowerCase() === 'a') return 'Excellent';
    if (value.toLowerCase() === 'b') return 'Bon';
    if (value.toLowerCase() === 'c') return 'Moyen';
    if (value.toLowerCase() === 'd') return 'Médiocre';
    if (value.toLowerCase() === 'e') return 'Très mauvais';
  }
  if (type === 'co2') {
    if (value < 2) return 'Très faible';
    if (value < 5) return 'Faible';
    if (value < 10) return 'Élevée';
    return 'Très élevée';
  }
  return 'Inconnu';
};

const ProductDetails = ({ productDetails }) => {
  const co2Total = productDetails?.ecoscore_data?.agribalyse?.co2_total || 0;
  const formattedCO2 = formatCO2Value(co2Total, productDetails);
  const impactDesc = getImpactDescription('co2', co2Total);
  const trueImpactco2 = getTrueImpactco2(co2Total, productDetails.quantity);

  // Calcul du poids en kg pour appliquer le coefficient aux détails carbone (si nécessaire)
  const weightInKg = (() => {
    const quantity = productDetails.quantity || '';
    const numStr = (quantity.match(/[\d.,]+/) || ['1'])[0].replace(',', '.');
    const num = parseFloat(numStr);
    let weight = num;
    const qLower = quantity.toLowerCase();
    if (qLower.includes('kg')) weight = num;
    else if (qLower.includes('g')) weight = num / 1000;
    else if (qLower.includes('mg')) weight = num / 1e6;
    else if (qLower.includes('l')) weight = num;
    else if (qLower.includes('ml')) weight = num / 1000;
    else if (qLower.includes('cl')) weight = num / 100;
    return weight;
  })();

// Préparation du contenu détaillé pour les détails carbone en appliquant le coefficient
const carbonDetails = (
  <Box component="ul" sx={{ pl: 3, m: 0 }}>
    {Object.entries(productDetails?.ecoscore_data?.agribalyse || {})
      .filter(([key]) => key.startsWith('co2'))
      .map(([key, value]) => {
        const computed = value ? Number(value * weightInKg).toFixed(2) : '?';
        return (
          <li key={key}>
            <Typography variant="body2" sx={{ color: '#666' }}>
              {key.replace('_', ' ')} :{' '}
              <Box component="span" sx={{
                fontSize: '1.2rem',  // Taille plus grande
                fontWeight: 'bold',  // Texte en gras
                color: '#333'  // Couleur plus foncée pour la lisibilité
              }}>
                {computed} kg CO₂
              </Box>
            </Typography>
          </li>
        );
      })}
  </Box>
);

  const scoreDescriptions = [
    {
      logo: '/images/ecoscore_a.png',
      title: 'Green Score',
      description: (
        <div>
          Le <strong style={{ fontSize: '1.1em', color: '#666' }}>Green Score</strong> mesure l'impact environnemental global du produit. Un score élevé indique un impact <strong style={{ fontSize: '1.1em', color: '#666' }}>réduit</strong> sur l'environnement.
          <br />
          Pour plus d&apos;infos, consultez la&nbsp;
          <a
            href="https://docs.score-environnemental.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#578c47', textDecoration: 'underline' }}
          >
            documentation Score Environnemental
          </a>.
          <br />
          <img
            src="/images/greenscore-details.svg"
            alt="Détails Green Score"
            style={{ maxWidth: '100%', marginTop: '8px' }}
          />
        </div>
      ),
    },
    {
      logo: '/images/nutriscore_a.png',
      title: 'Nutri-Score',
      description: (
        <div>
          Le <strong style={{ fontSize: '1.1em', color: '#666' }}>Nutri-Score</strong> évalue la qualité nutritionnelle du produit, allant de A (optimal) à E (moins favorable).
          <br />
          Pour plus d&apos;infos, consultez&nbsp;
          <a
            href="https://www.santepubliquefrance.fr/determinants-de-sante/nutrition-et-activite-physique/articles/nutri-score"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#578c47', textDecoration: 'underline' }}
          >
            les informations de Santé Publique France
          </a>.
          <br />
          <img
            src="/images/nutriscore-details.png"
            alt="Détails Nutri-Score"
            style={{ maxWidth: '100%', marginTop: '8px' }}
          />
        </div>
      ),
    },
    {
      logo: '/images/nova_1.png',
      title: 'Nova Score',
      description: (
        <div>
          Le <strong style={{ fontSize: '1.1em', color: '#666' }}>Nova Score</strong> indique le degré de transformation du produit. Un score bas signifie un produit <strong style={{ fontSize: '1.1em', color: '#666' }}>peu transformé</strong> et souvent plus sain.
          <br />
          Pour plus d&apos;infos, consultez&nbsp;
          <a
            href="https://fr.wikipedia.org/wiki/NOVA_(nutrition)"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#578c47', textDecoration: 'underline' }}
          >
            la page Wikipedia
          </a>.
          <br />
          <img
            src="/images/novascore-details.png"
            alt="Détails Nova Score"
            style={{ maxWidth: '100%', marginTop: '8px' }}
          />
        </div>
      ),
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
              {/* Image du produit */}
      <Box
        sx={{
          width: '100%',
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          overflow: 'hidden',
          mb: 2,
        }}
      >
        {productDetails.image_url ? (
          <img
            src={productDetails.image_url}
            alt={productDetails.product_name}
            style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
          />
        ) : (
          <Typography variant="caption">Image non disponible</Typography>
        )}
      </Box>
      {/* Nom et marque */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1 }}>
        {productDetails.product_name || 'Nom inconnu'}
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center', color: 'gray', mb: 2 }}>
        {productDetails.brands || 'Marque inconnue'}
      </Typography>

      {/* Section Scores */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <CardMedia
          component="img"
          src={`/images/ecoscore_${productDetails.ecoscore_grade || 'unknown'}.png`}
          alt="Green Score"
          sx={{
            height: 40,
            width: 'auto',
            objectFit: 'contain',
          }}
        />
        <CardMedia
          component="img"
          src={`/images/nutriscore_${productDetails.nutriscore_grade || 'unknown'}.png`}
          alt="Nutri-Score"
          sx={{
            height: 40,
            width: 'auto',
            objectFit: 'contain',
          }}
        />
        <CardMedia
          component="img"
          src={`/images/nova_${productDetails.nova_group || 'unknown'}.png`}
          alt="Nova Score"
          sx={{
            height: 40,
            width: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>

      <ScoreOverview scoreDescriptions={scoreDescriptions} />

      {/* Impact CO₂ et détails intégrés dans une bulle déroulante */}
      <CO2DetailsBubble
        formattedCO2={formattedCO2}
        impactDescription={impactDesc}
        details={carbonDetails}
        co2Total={trueImpactco2}    
        ecoscore_grade={productDetails.ecoscore_grade}
      />

      {/* Comparateur CO₂ */}
      <CO2Comparator value={trueImpactco2} />

      {/* Section Informations nutritionnelles */}
      <Typography
        variant="h6"
        sx={{ mt: 3, color: '#578c47', fontWeight: 'bold', mb: 2 }}
      >
        Informations nutritionnelles
      </Typography>
      {productDetails.ingredients_text && (
        <IngredientsSection ingredients={productDetails.ingredients_text} />
      )}
      {productDetails.nutriments && (
        <NutritionalValuesSection nutriments={productDetails.nutriments} />
      )}
    </Box>
  );
};

export default ProductDetails;
