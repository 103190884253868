import BuildIcon from '@mui/icons-material/Build';
import InstallIcon from '@mui/icons-material/GetApp';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, List, ListItem, ListItemText, Stack, Toolbar, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// icon loop de recherche 



const Navbar = ({ isAdmin }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('up');
  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setScrollDirection('down');
    } else if (currentScrollY < lastScrollY || currentScrollY <= 100) {
      setScrollDirection('up');
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: '#ffffff',
          padding: 1,
          transition: 'top 0.3s',
          top: scrollDirection === 'down' ? '-100px' : isAdmin ? '10px' : '0',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1200,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ justifyContent: { xs: 'space-between', md: 'space-between' } }}>
            {/* Mobile Navigation Button (Hamburger Icon) */}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                display: { xs: 'flex', md: 'none' },
                color: '#4CAF50',
                position: 'absolute',
                left: 10, // Colle l'icône à l'extrême gauche
                padding: 0, // Supprime tout padding résiduel
              }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>


            {/* Logo centré pour mobile, aligné à gauche pour desktop */}
            <Box
              component={Link}
              to="/"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', md: 'flex-start' },
                textDecoration: 'none',
                flexGrow: { xs: 1, md: 0 },
              }}
            >
              <img src="/images/IndiceFossileLogo.png" alt="Indice Fossile Logo" style={{ height: 55 }} />
            </Box>

            {/* Desktop Navigation Links with tooltips */}
            <Stack direction="row" spacing={3} sx={{ ml: 'auto', display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Accédez à l'espace dédié aux membres" arrow placement="bottom">
                <Button
                  color="inherit"
                  component={Link}
                  to="/espace-membres"
                  sx={{
                    fontSize: '1rem',
                    color: '#388E3C',
                    '&:hover': { color: '#4CAF50' },
                  }}
                >
                  Soutenir 💚
                </Button>
              </Tooltip>
              <Tooltip title="Suivez notre actualité et l'évolution du projet" arrow placement="bottom">
                <Button
                  color="inherit"
                  component={Link}
                  to="/actualites"
                  sx={{
                    fontSize: '1rem',
                    color: '#388E3C',
                    '&:hover': { color: '#4CAF50' },
                  }}
                >
                  actualites
                </Button>
              </Tooltip>
              <Tooltip title="Services et classements pour organisations" arrow placement="bottom">
                <Button
                  color="inherit"
                  component={Link}
                  to="/"
                  sx={{
                    fontSize: '1rem',
                    color: '#388E3C',
                    '&:hover': { color: '#4CAF50' },
                  }}
                >
                  {/* icon de recherche barre de recherche */}
                  Catalogue
                <SearchIcon
                  sx={{
                    fontSize: '1.2rem',
                    color: '#388E3C',
                    ml: 0.5,
                  }}
                />
                </Button>
              </Tooltip>
              <Tooltip title="Installer l'application mobile" arrow placement="bottom">
                <Button
                  component={Link}
                  to="/application"
                  color="inherit"
                  sx={{
                    fontSize: '1rem',
                    color: '#388E3C',
                    '&:hover': { color: '#4CAF50' },
                  }}
                >
                  Installer l'App
                <InstallIcon
                  sx={{
                    fontSize: '1.2rem',
                    color: '#388E3C',
                    ml: 0.5,
                  }}
                />
                </Button>
              </Tooltip>

            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          zIndex: 1300,
        }}
      >
        <Box
          sx={{
            width: 250,
            bgcolor: '#f4f6f9',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box sx={{ py: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src="/images/IndiceFossileLogo.png" alt="Indice Fossile Logo" style={{ height: 55 }} />
          </Box>
          <Divider sx={{ my: 2, bgcolor: '#B0BEC5', height: '1px', width: '80%' }} />
          <List>
            <ListItem button component={Link} to="/espace-membres">
              <ListItemText primary="Soutenir 💚" sx={{ textAlign: 'center', color: '#388E3C' }} />
            </ListItem>
            <ListItem button component={Link} to="/actualites">
              <ListItemText primary="Actualités" sx={{ textAlign: 'center', color: '#388E3C' }} />
            </ListItem>
            <ListItem button component={Link} to="/">
              <ListItemText primary="Catalogue" sx={{ textAlign: 'center', color: '#388E3C' }} />
            </ListItem>
          </List>
          <Divider sx={{ my: 2, bgcolor: '#B0BEC5', height: '1px', width: '80%' }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <Button
              component={Link}
              to="/application"
              variant="contained"
              sx={{
                bgcolor: '#4CAF50',
                color: '#ffffff',
                fontWeight: 'bold',
                '&:hover': { bgcolor: '#388E3C' },
                width: '80%',
              }}
            >
              Installer l'App
            </Button>
          </Box>
          <BuildIcon
              sx={{
                fontSize: '1.2rem',
                color: '#666',
                mr: 0.5,
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: '0.8rem', sm: '0.9rem' },
                color: '#666',
                fontStyle: 'italic',
                textAlign: 'center',
              }}
            >
              En cours de développement
            </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
