import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import React from 'react';

const SearchBar = ({ query, setQuery, handleSearch, handleCancelSearch, handleKeyPress, inputRef, placeholderText, loading }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '90%',
        maxWidth: '600px',
        mx: 'auto',
      }}
    >
      <TextField
        inputRef={inputRef}
        fullWidth
        value={query}
        placeholder={placeholderText}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress} // ✅ Correction : Utilisation de la prop passée
        disabled={loading}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: 'green',
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={loading ? 'Annuler la recherche' : 'Rechercher'}>
                <IconButton
                  onClick={loading ? handleCancelSearch : handleSearch} // ✅ Correction : Utilisation de la prop passée
                  sx={{
                    color: 'green',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 128, 0, 0.1)',
                    },
                  }}
                >
                  {loading ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        sx={{
          borderRadius: '20px',
          backgroundColor: 'white',
          input: {
            color: 'black',
          },
          '.MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'green',
              borderRadius: '20px',
            },
            '&:hover fieldset': {
              borderColor: 'darkgreen',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'darkgreen',
            },
          },
        }}
      />
    </Box>
  );
};

export default SearchBar;
