import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';

const ConditionDUtilisation = () => {
  return (
    <Container sx={{ mt: 12, mb: 6, textAlign: 'left', maxWidth: '800px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#388E3C', textAlign: 'center', mb: 3 }}>
        Conditions Générales d’Utilisation
      </Typography>

      <Paper elevation={3} sx={{ p: 4, borderRadius: '12px', backgroundColor: 'white', boxShadow: '0px 4px 10px rgba(0,0,0,0.1)', width: '800px', margin: 'auto' }}>
        <Typography variant="body1" sx={{ fontSize: '1rem', color: '#555', mb: 2, textAlign: 'center' }}>
          Dernière mise à jour : <strong>25/01/2025</strong>
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>1. Objet des CGU</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Les présentes Conditions Générales d’Utilisation (CGU) régissent l’accès et l’utilisation du site internet 
            <a href="https://indicefossile.fr/" target="_blank" rel="noopener noreferrer" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}>
              {' '}https://indicefossile.fr/
            </a>.
            En accédant au Site, vous acceptez sans réserve les présentes CGU.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>2. Informations légales</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            <strong>Éditeur du site :</strong> Victor MICHEL, Directeur de publication de l'association. <br />
            <strong>Adresse :</strong> 50 allées Charles de Fittes, 31300 Toulouse, France <br />
            <strong>Contact :</strong> 07 84 34 23 91 - 
            <a href="mailto:contact@indice-fossile.io" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}> contact@indice-fossile.io</a>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>3. Accès au site</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            L’accès au Site est gratuit. L’association se réserve le droit de modifier, suspendre ou interrompre 
            l’accès à tout ou partie du Site sans préavis, notamment pour des raisons techniques ou de maintenance.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>4. Propriété intellectuelle</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Tous les contenus présents sur le Site (textes, images, logos, vidéos, etc.) sont protégés par le droit 
            de la propriété intellectuelle et sont la propriété exclusive de l’association ou de ses partenaires.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>5. Données personnelles</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Le Site respecte la réglementation en vigueur en matière de protection des données personnelles 
            (RGPD). Pour toute question relative à la gestion de vos données personnelles, contactez 
            <a href="mailto:jldebeauminy@gmail.com" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}> jldebeauminy@gmail.com</a>.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>6. Responsabilité</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            L’association met tout en œuvre pour assurer l’exactitude des informations publiées sur le Site, 
            mais décline toute responsabilité en cas d’erreurs ou d’omissions.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>7. Liens hypertextes</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Le Site peut contenir des liens vers des sites externes. L’association ne saurait être tenue responsable 
            du contenu ou des services proposés sur ces sites tiers.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>8. Modification des CGU</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Les présentes CGU peuvent être modifiées à tout moment par l’association. Nous vous recommandons de 
            consulter régulièrement cette page pour prendre connaissance des éventuelles mises à jour.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>9. Droit applicable</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Les présentes CGU sont régies par le droit français. En cas de litige, les tribunaux compétents de 
            Toulouse seront seuls habilités à en connaître.
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>📩 Contact</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Pour toute question relative aux présentes CGU : <br />
            ✉️ <a href="mailto:jldebeauminy@gmail.com" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}>
            jldebeauminy@gmail.com
            </a> <br />
            📍 50 allées Charles de Fittes, 31300 Toulouse, France
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ConditionDUtilisation;
