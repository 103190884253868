// IngredientsSection.js
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';

const IngredientsSection = ({ ingredients }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prev) => !prev);

  return (
    <Box
      sx={{
        border: '2px solid #A8D5BA',
        borderRadius: '16px',
        bgcolor: 'rgba(200,230,201,0.15)',
        transition: 'all 0.3s ease',
        mb: 2,
      }}
    >
      {/* En-tête cliquable */}
      <Box
        onClick={toggleMenu}
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            borderColor: '#8CBF88',
            bgcolor: 'rgba(200,230,201,0.3)',
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#578c47' }}>
          Liste des ingrédients
        </Typography>
        <IconButton size="small" sx={{ p: 0 }}>
          {isOpen ? <ExpandLessIcon sx={{ color: '#578c47' }} /> : <ExpandMoreIcon sx={{ color: '#578c47' }} />}
        </IconButton>
      </Box>
      {/* Contenu déroulé */}
      <Collapse in={isOpen} timeout={300} unmountOnExit>
        <Box sx={{ p: 2 }}>
          {ingredients ? (
            <Typography variant="body2" sx={{ color: '#666' }}>
              {ingredients}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: '#757575' }}>
              Aucune information disponible sur les ingrédients.
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default IngredientsSection;
