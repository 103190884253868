import BuildIcon from '@mui/icons-material/Build';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

const DownloadButton = () => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);

    // Réinitialise l'état après l'animation
    setTimeout(() => {
      setClicked(false);
    }, 300); // Durée de l'animation de clic
  };

  return (
    <Box
      sx={{
        width: { xs: '90%', sm: '100%', md: '100%' },
        margin: '0 auto',
        textAlign: 'center',
        mb: 4,
      }}
    >
      <Box
        onClick={handleClick} // Animation déclenchée au clic
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: { xs: '100%', sm: '350px' },
          height: { xs: '60px', sm: '70px' },
          overflow: 'hidden',
          borderRadius: '20px',
          backgroundColor: '#D9D9D9',
          textAlign: 'center',
          lineHeight: { xs: '60px', sm: '70px' },
          fontWeight: 'bold',
          fontSize: { xs: '1rem', sm: '1.2rem' },
          fontFamily: "'Poppins', sans-serif",
          color: '#4CAF50',
          boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
          transform: clicked ? 'scale(0.95)' : 'scale(1)', // Effet de clic
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Transition fluide
          pointerEvents: clicked ? 'none' : 'auto', // Désactive les hover pendant le clic
          '&:hover': {
            transform: clicked ? 'scale(0.95)' : 'scale(1.03)', // Désactive hover pendant clic
            boxShadow: clicked
              ? '0px 6px 15px rgba(0, 0, 0, 0.2)' // Pas de hover pendant clic
              : '0px 8px 20px rgba(0, 0, 0, 0.3)', // Effet hover normal
          },
        }}
      >
        {/* Rectangle avec progression et animation de dégradé */}
        <Box
          sx={{
            position: 'absolute',
            top: '4px',
            left: '4px',
            width: '40%',
            height: 'calc(100% - 8px)',
            borderRadius: 'inherit',
            background: 'linear-gradient(90deg, #6CAF6A, #A4D4A8, #6CAF6A)',
            backgroundSize: '200% 100%',
            animation: 'progressAnimation 5s linear infinite',
            zIndex: 1,
            '@keyframes progressAnimation': {
              '0%': {
                backgroundPosition: '0% 0%',
              },
              '100%': {
                backgroundPosition: '-200% 0%',
              },
            },
          }}
        />

        {/* Texte du bouton */}
        <Box
          component="span"
          sx={{
            position: 'relative',
            zIndex: 2,
            color: '#4CAF50',
          }}
        >
          APP BIENTÔT DISPONIBLE ...
        </Box>
      </Box>

      {/* Texte indicatif sous le bouton avec l'icône */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 1,
        }}
      >
        <BuildIcon
          sx={{
            fontSize: '1.2rem',
            color: '#666',
            mr: 0.5,
          }}
        />
        <Typography
          sx={{
            fontSize: { xs: '0.8rem', sm: '0.9rem' },
            color: '#666',
            fontStyle: 'italic',
            textAlign: 'center',
          }}
        >
          En cours de développement
        </Typography>
      </Box>
    </Box>
  );
};

export default DownloadButton;
