import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';

const MentionLegales = () => {
  return (
    <Container sx={{ mt: 12, mb: 6, textAlign: 'left', maxWidth: '800px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#388E3C', textAlign: 'center', mb: 3 }}>
        Mentions légales
      </Typography>

      <Paper elevation={3} sx={{ p: 4, borderRadius: '12px', backgroundColor: 'white', boxShadow: '0px 4px 10px rgba(0,0,0,0.1)', 
        // 800px pour pc et 100% pour mobile
        width: { xs: '100%', md: '800px' },
        margin: 'auto' }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Éditeur du site</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Le présent site est édité par l’association Indice Fossile, déclarée sous le régime de la loi du 1er juillet 1901 et inscrite auprès de la Préfecture de Haute-Garonne.
          </Typography>
          <Typography variant="body1" sx={{ color: '#555', mt: 1 }}>
            <strong>Nom de l’Association :</strong> Indice Fossile <br />
            <strong>Adresse :</strong> 50 allée Charles de Fittes, 31300 Toulouse <br />
            <strong>Numéro RNA :</strong> W313038394 <br />
            <strong>Numéro SIRET :</strong> 924 259 286 00012 <br />
            <strong>Contact :</strong> 07 84 34 23 91 - 
            <a href="mailto:contact@indicefossile.com" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}> contact@indicefossile.com</a>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Hébergeur du site</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Le site est hébergé par <strong>IONOS SARL</strong>. <br />
            📍 <strong>Adresse :</strong> 17, place de la Gare - BP 70109, 57201 Sarreguemines, France <br />
            📞 <strong>Téléphone :</strong> 09 70 80 89 11
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Objet du site</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Le site <a href="https://indicefossile.fr/" target="_blank" rel="noopener noreferrer" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}>
              https://indicefossile.fr/
            </a> a pour objectif de présenter les activités et actions de l’association Indice Fossile. Il permet aux membres et au public de consulter des informations sur ses projets, événements et engagements.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Protection des données personnelles</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Conformément au Règlement Général sur la Protection des Données (RGPD), l’association garantit la protection des données personnelles des utilisateurs.
            <br />
            <strong>Délégué à la protection des données :</strong> Jean-Loup De Beauminy
            <br />
            📩 <a href="mailto:contact@indicefossile.com" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}> contact@indicefossile.com</a>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Données collectées</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            L’association collecte et traite des données personnelles dans les cadres suivants :
          </Typography>

          <ul style={{ color: '#555', paddingLeft: '20px', marginTop: '10px' }}>
            <li><strong>Formulaire d’adhésion :</strong> Nom, prénom, e-mail, code postal</li>
            <li><strong>Newsletter :</strong> E-mail, prénom, nom</li>
            <li><strong>Formulaire de dons :</strong> Nom, prénom, date, montant du don</li>
            <li><strong>Espace membre :</strong> Nom, prénom, identifiant, mot de passe</li>
          </ul>

          <Typography variant="body1" sx={{ color: '#555', mt: 2 }}>
            Ces données sont conservées pendant **3 ans** et sécurisées par des accès restreints et mots de passe.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Propriété intellectuelle</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            L’ensemble des contenus présents sur ce site (textes, images, logos, vidéos) est protégé par le droit d’auteur et reste la propriété exclusive de l’association Indice Fossile.
            Toute reproduction, représentation ou diffusion sans autorisation préalable est interdite, sauf autorisation écrite de l’association.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Liens hypertextes</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Ce site peut contenir des liens hypertextes vers des sites tiers. L’association Indice Fossile ne saurait être tenue responsable du contenu de ces sites externes.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>Responsabilité</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            L’association met tout en œuvre pour assurer l’exactitude des informations diffusées sur le site, mais ne saurait être tenue responsable en cas d’erreur, omission ou interruption du site.
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>📩 Contact</Typography>
          <Typography variant="body1" sx={{ color: '#555' }}>
            Pour toute question ou réclamation concernant ce site : <br />
            ✉️ <a href="mailto:contact@indicefossile.com" style={{ color: '#388E3C', textDecoration: 'none', fontWeight: 'bold' }}> contact@indicefossile.com</a>
            📍 50 allée Charles de Fittes, 31300 Toulouse, France
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default MentionLegales;
