// ScoreOverview.js
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ScoreBubble from './ScoreBubble';

const ScoreOverview = ({ scoreDescriptions }) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef(null);

  const toggleExpanded = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  // Fermer le contenu déroulé si on clique en dehors du conteneur
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        width: '90%',
        maxWidth: '600px',
        mx: 'auto',
        mt: 1,
        mb: 2,
      }}
    >
      {/* Bulle principale */}
      <Box
        onClick={toggleExpanded}
        sx={{
          backgroundColor: 'rgba(200,230,201,0.3)',
          borderRadius: '16px',
          p: "3px 5px",
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            borderColor: '#8CBF88',
            backgroundColor: 'rgba(200,230,201,0.4)',
          },
        }}
      >
        {/* Icône info entourée */}
        <Box
          sx={{
            borderRadius: '50%',
            p: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <InfoOutlinedIcon sx={{ color: '#578c47', fontSize: '1.2rem' }} />
        </Box>
        <Typography
          variant="subtitle1"
          sx={{ color: '#578c47', fontWeight: 'bold', flexGrow: 1, whiteSpace: 'nowrap' }}
        >
          Comprendre les scores
        </Typography>
        <IconButton onClick={toggleExpanded} sx={{ p: 0 }}>
          {expanded ? (
            <ExpandLessIcon sx={{ color: '#578c47' }} />
          ) : (
            <ExpandMoreIcon sx={{ color: '#578c47' }} />
          )}
        </IconButton>
      </Box>

      {/* Contenu déroulé avec animation (ouverture et fermeture) */}
      <Box
        sx={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          mt: 1,
          p: 2,
          border: '2px solid #A8D5BA',
          borderRadius: '16px',
          backgroundColor: '#e9f2e2',
          zIndex: 10000,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          opacity: expanded ? 1 : 0,
          transform: expanded ? 'translateY(0)' : 'translateY(-20px)',
          transition: 'opacity 0.3s ease, transform 0.3s ease',
          pointerEvents: expanded ? 'auto' : 'none',
        }}
      >
        {scoreDescriptions.map((score, index) => (
          <ScoreBubble
            key={index}
            logo={score.logo}
            title={score.title}
            description={score.description}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ScoreOverview;
