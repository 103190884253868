import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import ProductModal from '../productModal/ProductModal';
import ProductCard from './ProductCard';

const ProductList = ({ products, loading }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <Box sx={{ paddingX: 8, paddingY: 2 }}>
      {loading && (
        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography variant="h6" color="textSecondary" sx={{ marginBottom: 2 }}>
            Chargement des produits...
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Cela peut prendre jusqu'à 1min selon votre recherche.
          </Typography>
        </Box>
      )}
      <Grid container spacing={3}>
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={`loading-${index}`}>
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" width="60%" sx={{ marginBottom: 1 }} />
                <Skeleton variant="text" width="40%" />
              </Grid>
            ))
          : products.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product._id || product.code || Math.random()}>
                <ProductCard product={product} onClick={() => handleOpenModal(product)} />
              </Grid>
            ))}
      </Grid>

      {selectedProduct && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
    </Box>
  );
};

export default ProductList;
