import { useEffect } from 'react';

const PlaceholderText = ({ setPlaceholderText }) => {
  useEffect(() => {
    const words = ['Pâtes complètes', 'Chocolat noir 80%', 'Yaourt nature'];
    let index = 0, charIndex = 0, isDeleting = false;

    const animate = () => {
      const word = words[index];
      setPlaceholderText(isDeleting ? word.slice(0, charIndex--) : word.slice(0, charIndex++));

      if (!isDeleting && charIndex === word.length) {
        setTimeout(() => (isDeleting = true), 700);
      } else if (isDeleting && charIndex === 0) {
        isDeleting = false;
        index = (index + 1) % words.length;
        setTimeout(animate, 500);
        return;
      }
      setTimeout(animate, isDeleting ? 50 : 100);
    };

    animate();
  }, [setPlaceholderText]);

  return null;
};

export default PlaceholderText;
